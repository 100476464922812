import {useContext} from "react";
import CookieContext from '../Context/CookieContext'

const CookieOption = ({tid, text, disabled = false}) => {
  const htmlId = `cookies-settings-${tid}`
  const cookieContext = useContext(CookieContext)
  const checked = cookieContext.cookies[tid]||false
  const toggle = (checked) => {
    cookieContext.setOption(tid, checked)
  }

  return <div className="switch">
    <input type="checkbox" name={`cm-${tid}`} id={htmlId} onChange={() => !disabled && toggle(!checked)}
           data-cookie-type={tid} className="switch__input" value="1" checked={checked} disabled={disabled}/>
    <label htmlFor={htmlId} className={"switch__button cookies-manager-border-color-gray "
      + (disabled ? " cookies-manager-background-color-burgundy " : " cookies-manager-background-color-cod-gray ")
      + (!disabled && checked ? "cookies-manager-color-monza cookies-manager-background-color-monza" : "")}/>
    <label htmlFor={htmlId} className={"switch__label cookies-manager-color-white " + (!disabled ? '' : ' switch__label--disabled')}>{text}</label>
  </div>
}

export default CookieOption
