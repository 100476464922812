import {_} from '../Translations/Translations'

const HeaderText = () => (
  <div className="cookies-bar__text cookies-manager-color-white  formatted-text">
    <p className="cookies-bar__text__heading">{_("title")}</p>
    <div dangerouslySetInnerHTML={{__html: _('annotation')}} />
  </div>
)

export default HeaderText
