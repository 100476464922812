// Dictionary
const Dictionary = {
  missingTranslation: {
    cz: 'Překlad nenalezen',
    en: 'Translation is missing',
    de: 'Übersetzung fehlt',
    pl: 'Brakuje tłumaczenia',
    ro: 'Lipsește traducerea',
    hu: 'A fordítás hiányzik',
    sk: 'Chýba preklad'
  },
  title: {
    cz: 'Stránky a aplikace využívají cookies.',
    en: 'Sites and applications use cookies. ',
    de: 'Websites und Anwendungen verwenden Cookies. ',
    pl: 'Witryny i aplikacje używają plików cookie. ',
    ro: 'Site-urile și aplicațiile folosesc cookie-uri. ',
    hu: 'A webhelyek és alkalmazások cookie-kat használnak. ',
    sk: 'Stránky a aplikácie využívajúce cookies. '
  },
  annotation: {
    cz: 'Cookies jsou soubory, které se ukládají ve vašem zařízení při načtení webové stránky, díky nim můžeme snadněji identifikovat způsob, jakým pracujete s webovými stránkami, vstřícněji s vámi komunikovat, odhalit podvodné chování nebo cílit marketingové aktivity. Typy cookies a podrobnější informace naleznete popsané níže, můžete si zde zvolit svou preferovanou variantu. Souhlas můžete kdykoliv změnit nebo zrušit. ',
    en: 'Cookies are files that are stored on your device when a website is loaded, thanks to which we can more easily identify the way you work with the website, communicate with you more friendly, detect fraudulent behavior or target marketing activities. You will find the types of cookies and more detailed information described below, you can choose your preferred variant here. You can change or cancel your consent at any time. ',
    de: 'Cookies sind Dateien, die beim Laden einer Website auf Ihrem Gerät gespeichert werden, dank derer wir leichter erkennen können, wie Sie mit der Website arbeiten, mit Ihnen freundlicher kommunizieren, betrügerisches Verhalten erkennen oder Marketingaktivitäten ausrichten können. Nachfolgend finden Sie die Arten von Cookies und nähere Informationen, Sie können hier Ihre bevorzugte Variante auswählen. Sie können Ihre Einwilligung jederzeit ändern oder widerrufen. ',
    pl: 'Pliki cookie to pliki, które są zapisywane na Twoim urządzeniu podczas ładowania strony, dzięki czemu możemy łatwiej zidentyfikować sposób, w jaki pracujesz ze stroną, bardziej przyjaźnie się z Tobą komunikować, wykryć nieuczciwe zachowania lub ukierunkować działania marketingowe. Poniżej znajdziesz rodzaje plików cookie i bardziej szczegółowe informacje, tutaj możesz wybrać preferowany wariant. W każdej chwili możesz zmienić lub anulować swoją zgodę. ',
    ro: 'Cookie-urile sunt fișiere care sunt stocate pe dispozitivul dvs. atunci când se încarcă un site web, datorită cărora putem identifica mai ușor modul în care lucrați cu site-ul web, putem comunica mai prietenos cu dvs., detecta comportamentul fraudulos sau viza activitățile de marketing. Veți găsi tipurile de cookie-uri și informații mai detaliate descrise mai jos, puteți alege varianta preferată aici. Vă puteți modifica sau anula consimțământul în orice moment. ',
    hu: 'A cookie-k olyan fájlok, amelyek egy webhely betöltésekor az Ön eszközén tárolódnak, amelyeknek köszönhetően könnyebben azonosíthatjuk a weboldallal való munkavégzés módját, barátságosabban kommunikálhatunk Önnel, észlelhetjük a csaló magatartást vagy megcélozhatjuk a marketingtevékenységeket. A sütik típusait és részletesebb információkat az alábbiakban találja, itt választhatja ki a kívánt változatot. Hozzájárulását bármikor módosíthatja vagy visszavonhatja. ',
    sk: 'Cookies sú súbory, ktoré sa ukladajú vo vašom zariadení pri načítaní webovej stránky, vďaka nim môžeme ľahšie identifikovať spôsob, akým pracujete s webovými stránkami, ústretovejšie s vami komunikovať, odhaliť podvodné správanie alebo cieliť marketingové aktivity. Typy cookies a podrobnejšie informácie nájdete popísané nižšie, môžete si tu zvoliť svoju preferovanú variantu. Súhlas môžete kedykoľvek zmeniť alebo zrušiť. '
  },

  button: {
    confirmNecessary: {
      cz: 'Pouze nezbytné cookies',
      en: 'Only necessary cookies',
      de: 'Nur notwendige Cookies',
      pl: 'Tylko niezbędne pliki cookie',
      ro: 'Doar cookie-uri necesare',
      hu: 'Csak a szükséges sütik',
      sk: 'Iba nevyhnutné cookies'
    },
    confirmSelected: {
      cz: 'Potvrdit vybrané',
      en: 'Confirm selected',
      de: 'Auswahl bestätigen',
      pl: 'Potwierdź wybrane',
      ro: 'Confirmați selectat',
      hu: 'Erősítse meg a kiválasztottat',
      sk: 'Potvrdiť vybrané'
    },
    confirmAll: {
      cz: 'Povolit všechny cookies',
      en: 'Allow all cookies',
      de: 'Alle Cookies zulassen ',
      pl: 'Zezwól na wszystkie pliki cookie',
      ro: 'Permiteți toate cookie-urile',
      hu: 'Engedélyezze az összes cookie-t',
      sk: 'Povoliť všetky cookies'
    },
    infoShow: {
      cz: 'Zobrazit cookie info',
      en: 'Show cookie info',
      de: 'Cookie-Info anzeigen',
      pl: 'Pokaż informacje o plikach cookie',
      ro: 'Afișați informații despre cookie-uri',
      hu: 'Cookie-információk megjelenítése',
      sk: 'Zobraziť cookie info'
    },
    infoHide: {
      cz: 'Skrýt cookie info',
      en: 'Hide information',
      de: 'Informationen ausblenden',
      pl: 'Ukryj informacje',
      ro: 'Ascunde informațiile',
      hu: 'Cookie-információk elrejtése',
      sk: 'Skryť cookie info'
    },
    moreOptions: {
      cz: 'Podrobnější volby',
      en: 'More options',
      de: 'Mehr Optionen',
      pl: 'Więcej opcji',
      ro: 'Mai multe opțiuni',
      hu: 'Több lehetőség',
      sk: 'Viac možností'
    }
  },
  cookie: {
    technical: {
      option: {
        cz: 'Nezbytné',
        en: 'Necessary',
        de: 'Notwendig ',
        pl: 'Koniecznie',
        ro: 'Neapărat',
        hu: 'Szükségszerűen',
        sk: 'Nevyhnutné'
      },
      info: {
        cz: 'Tyto cookies umožnují zobrazení, fungování webu a aplikací.\n' +
          'Většinou jsou nastavené jako odezva na akce, které jste provedl(a), jako je požadavek služeb\n' +
          'týkajících se bezpečnostních nastavení, přihlašování, vyplňování formulářů atp. Webová stránka nemůže správně fungovat bez těchto cookies.',
        en: 'These cookies allow you to view, operate the website and applications.\n' +
          'They are usually set in response to actions you have taken, such as requesting services related to security settings, logging in, ' +
          'filling out forms, and so on. The website cannot function properly without these cookies. ',
        de: 'Diese Cookies ermöglichen die Anzeige, den Betrieb der Website und der Anwendungen.\n' +
          'Sie werden normalerweise als Reaktion auf von Ihnen durchgeführte Aktionen festgelegt, z. B. das Anfordern von Diensten im Zusammenhang mit Sicherheitseinstellungen, das Anmelden, ' +
          'das Ausfüllen von Formularen usw. Ohne diese Cookies kann die Website nicht richtig funktionieren. ',
        pl: 'Te pliki cookie umożliwiają wyświetlanie, działanie strony internetowej i aplikacji.\n' +
          'Zwykle są one ustawiane w odpowiedzi na podjęte działania, takie jak żądanie usług związanych z ustawieniami zabezpieczeń, logowanie, ' +
          'wypełnianie formularzy i tak dalej. Witryna nie może działać poprawnie bez tych plików cookie. ',
        ro: 'Aceste cookie-uri vă permit să vizualizați, să operați site-ul web și aplicatii.\n' +
          'Acestea sunt de obicei setate ca răspuns la acțiunile pe care le-ați întreprins, cum ar fi solicitarea de servicii legate de setările de securitate, ' +
          'autentificarea, completarea formularelor și așa mai departe. Site-ul web nu poate funcționa corect fără aceste cookie-uri.',
        hu: 'Ezek a cookie-k lehetővé teszik a webhely és az alkalmazások megjelenítését, működését.\n' +
          'Általában az Ön által végrehajtott műveletekre reagálva állítják be, mint például a biztonsági beállításokkal kapcsolatos szolgáltatások kérése, ' +
          'bejelentkezés, űrlapok kitöltése stb. A weboldal nem működhet megfelelően ezen cookie-k nélkül. ',
        sk: 'Tieto cookies umožnujú zobrazenie, fungovanie webu a aplikácií.\n' +
          'Väčšinou sú nastavené ako odozva na akcie, ktoré ste vykonali, ako je požiadavka služieb týkajúcich sa bezpečnostných nastavení, ' +
          'prihlasovania, vyplňovania formulárov atp. Webová stránka nemôže správne fungovať bez týchto cookies. '
      },
      content: {
        cz: [
          '<p class="heading">Zpracovatelé a příjemci</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Křenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Správné fungování webové stránky a aplikace</p>' +
          '<p class="heading">Doba zpracování</p>' +
          '<p> Po dobu návštěvy webových stránek </p>'
        ],
        en: [
          '<p class = "heading"> Processors and recipients </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Purpose </p>' +
          '<p> Website and application working properly </p>' +
          '<p class = "heading"> Processing time </p>' +
          '<p> While visiting the website </p>'
        ],
        de: [
          '<p class = "heading"> Prozessoren und Empfänger </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Zweck </p>' +
          '<p> Website und Anwendung funktionieren ordnungsgemäß </p>' +
          '<p class = "heading"> Bearbeitungszeit </p>' +
          '<p> Beim Besuch der Website </p>'
        ],
        pl: [
          '<p class = "heading"> Procesory i odbiorcy </p>' +
          '<p> <strong>SOVA NET, s.r.o. </strong>, IČO: 26281813 <br />Křenová 409/52, Trnitá, Brno </p>' +
          '<p class = "heading"> Cel </p>' +
          '<p> Witryna i aplikacja działają prawidłowo </p>' +
          '<p class = "heading"> Czas przetwarzania </p>' +
          '<p> Podczas odwiedzania witryny </p>'
        ],
        ro: [
          '<p class = "heading"> Procesoare și destinatari </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Scop </p>' +
          '<p> Site-ul web și aplicația funcționează corect </p>' +
          '<p class = "heading"> Timp de procesare </p>' +
          '<p> În timp ce vizitați site-ul web </p>'
        ],
        hu: [
          '<p class = "heading"> Processzorok és címzettek </p>' +
          "<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>",
          '<p class = "heading"> Cél </p>' +
          '<p> A webhely és az alkalmazás megfelelően működik </p>' +
          '<p class = "heading"> Feldolgozási idő </p>' +
          "<p> A webhely felkeresése közben </p>"
        ],
        sk: [
          '<p class="heading">Spracovatelia a príjemcovia</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Krenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Správne fungovanie webovej stránky a aplikácie</p>' +
          '<p class="heading">Doba spracovania</p>' +
          '<p> Počas návštevy webových stránok </p>'
        ],
      }
    },
    preferential: {
      option: {
        cz: 'Preferenční',
        en: 'Preferential',
        de: 'Bevorzugt',
        pl: 'Preferencyjny',
        ro: 'Preferenţial',
        hu: 'Kedvezményes',
        sk: 'Preferenčné'
      },
      info: {
        cz: 'Tento typ cookies usnadňuje vyplňování formulářů, pamatují si vaše preference, proto nemusíte vaše údaje opětovně vypisovat. Používání těchto cookies není nezbytné, ale výrazně vám zpříjemní a ulehčí používání našich služeb. Zpracování těchto cookies lze odmítnout.',
        en: 'This type of cookie makes it easier to fill in forms, they remember your preferences, so you do not have to write your data again. The use of these cookies is not necessary, but it will make your use of our services much more pleasant and easier. The processing of these cookies can be refused.',
        de: 'Diese Art von Cookie erleichtert das Ausfüllen von Formularen, sie merken sich Ihre Präferenzen, sodass Sie Ihre Daten nicht erneut eingeben müssen. Die Verwendung dieser Cookies ist nicht erforderlich, wird Ihre Nutzung unserer Dienste jedoch wesentlich angenehmer und einfacher machen. Die Verarbeitung dieser Cookies kann abgelehnt werden.',
        pl: 'Ten rodzaj plików cookie ułatwia wypełnianie formularzy, zapamiętuje Twoje preferencje, dzięki czemu nie musisz ponownie wpisywać swoich danych. Korzystanie z tych plików cookie nie jest konieczne, ale sprawi, że korzystanie z naszych usług będzie o wiele przyjemniejsze i łatwiejsze. Można odmówić przetwarzania tych plików cookie.',
        ro: 'Acest tip de cookie ușurează completarea formularelor, ele își amintesc preferințele dvs., astfel încât nu trebuie să vă scrieți din nou datele. Utilizarea acestor cookie-uri nu este necesară, dar vă va face utilizarea serviciilor noastre mult mai plăcută și mai ușoară. Procesarea acestor cookie-uri poate fi refuzată.',
        hu: 'Ez a fajta süti megkönnyíti az űrlapok kitöltését, megjegyzik az Ön preferenciáit, így nem kell újra kiírnia adatait. Ezen sütik használata nem kötelező, de sokkal kellemesebbé és egyszerűbbé teszi szolgáltatásaink használatát. Ezen sütik feldolgozása megtagadható.',
        sk: 'Tento typ cookies uľahčuje vypĺňanie formulárov, pamätajú si vaše preferencie, preto nemusíte vaše údaje opätovne vypisovať. Používanie týchto cookies nie je nevyhnutné, ale výrazne vám spríjemní a uľahčí používanie našich služieb. Spracovanie týchto cookies je možné odmietnuť.'
      },
      content: {
        cz: [
          '<p class="heading">Zpracovatelé a příjemci</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Křenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Správné fungování webové stránky a aplikace</p>' +
          '<p class="heading">Doba zpracování</p>' +
          '<p> Po dobu návštěvy webových stránek </p>'
        ],
        en: [
          '<p class = "heading"> Processors and recipients </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Purpose </p>' +
          '<p> Website and application working properly </p>' +
          '<p class = "heading"> Processing time </p>' +
          '<p> While visiting the website </p>'
        ],
        de: [
          '<p class = "heading"> Prozessoren und Empfänger </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Zweck </p>' +
          '<p> Website und Anwendung funktionieren ordnungsgemäß </p>' +
          '<p class = "heading"> Bearbeitungszeit </p>' +
          '<p> Beim Besuch der Website </p>'
        ],
        pl: [
          '<p class = "heading"> Procesory i odbiorcy </p>' +
          '<p> <strong>SOVA NET, s.r.o. </strong>, IČO: 26281813 <br />Křenová 409/52, Trnitá, Brno </p>' +
          '<p class = "heading"> Cel </p>' +
          '<p> Witryna i aplikacja działają prawidłowo </p>' +
          '<p class = "heading"> Czas przetwarzania </p>' +
          '<p> Podczas odwiedzania witryny </p>'
        ],
        ro: [
          '<p class = "heading"> Procesoare și destinatari </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Scop </p>' +
          '<p> Site-ul web și aplicația funcționează corect </p>' +
          '<p class = "heading"> Timp de procesare </p>' +
          '<p> În timp ce vizitați site-ul web </p>'
        ],
        hu: [
          '<p class = "heading"> Processzorok és címzettek </p>' +
          "<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>",
          '<p class = "heading"> Cél </p>' +
          '<p> A webhely és az alkalmazás megfelelően működik </p>' +
          '<p class = "heading"> Feldolgozási idő </p>' +
          "<p> A webhely felkeresése közben </p>"
        ],
        sk: [
          '<p class="heading">Spracovatelia a príjemcovia</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Krenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Správne fungovanie webovej stránky a aplikácie</p>' +
          '<p class="heading">Doba spracovania</p>' +
          '<p> Počas návštevy webových stránok </p>'
        ],
      }
    },
    analytical: {
      option: {
        cz: 'Analytické',
        en: 'Analytical',
        de: 'Analytisch',
        pl: 'Analityczny',
        ro: 'Analitic',
        hu: 'Elemző',
        sk: 'Analytické'
      },
      info: {
        cz: 'Díky analytickým cookies máme přehled o využití webu díky tomu ho pro vás můžeme neustále vylepšovat. Například víme, jaké stránky jsou nejčastěji navštěvované, na která tlačítka uživatelé klikají apod. ',
        en: 'Thanks to analytical cookies, we have an overview of the use of the website, thanks to which we can constantly improve it for you. For example, we know which pages are most visited, which buttons users click, etc.',
        de: 'Dank analytischer Cookies haben wir einen Überblick über die Nutzung der Website, dank derer wir diese für Sie ständig verbessern können. Wir wissen beispielsweise, welche Seiten am häufigsten besucht werden, auf welche Schaltflächen Benutzer klicken usw.',
        pl: 'Dzięki analitycznym plikom cookie mamy wgląd w korzystanie z serwisu, dzięki czemu możemy go dla Ciebie stale ulepszać. Na przykład wiemy, które strony są najczęściej odwiedzane, które przyciski klikają użytkownicy itp.',
        ro: 'Datorită cookie-urilor analitice, avem o imagine de ansamblu asupra utilizării site-ului web, datorită căreia îl putem îmbunătăți în mod constant pentru dvs. De exemplu, știm ce pagini sunt cele mai vizitate, pe ce butoane pe care fac clic utilizatorii etc.',
        hu: 'Az analitikai sütiknek köszönhetően áttekintést kapunk a weboldal használatáról, aminek köszönhetően folyamatosan fejleszthetjük az Ön számára. Tudjuk például, hogy mely oldalak a leglátogatottabbak, mely gombokra kattintanak a felhasználók stb.',
        sk: 'Vďaka analytickým cookies máme prehľad o využívaní webu vďaka tomu ho pre vás môžeme neustále vylepšovať. Napríklad vieme, aké stránky sú najčastejšie navštevované, na ktoré tlačidlá používatelia klikajú a pod.'
      },
      content: {
        cz: [
          '<p class="heading">Zpracovatelé a příjemci</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Křenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Analýza návštěvnosti webu a chování uživatelů</p>' +
          '<p class="heading">Doba zpracování</p>' +
          '<p> Po dobu návštěvy webových stránek </p>'
        ],
        en: [
          '<p class = "heading"> Processors and recipients </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Purpose </p>' +
          '<p> Website traffic analysis and user behavior </p>' +
          '<p class = "heading"> Processing time </p>' +
          '<p> While visiting the website </p>'
        ],
        de: [
          '<p class = "heading"> Prozessoren und Empfänger </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Zweck </p>' +
          '<p> Website-Traffic-Analyse und Nutzerverhalten </p>' +
          '<p class = "heading"> Bearbeitungszeit </p>' +
          '<p> Beim Besuch der Website </p>'
        ],
        pl: [
          '<p class = "heading"> Procesory i odbiorcy </p>' +
          '<p> <strong>SOVA NET, s.r.o. </strong>, IČO: 26281813 <br />Křenová 409/52, Trnitá, Brno </p>' +
          '<p class = "heading"> Cel </p>' +
          '<p> Analiza ruchu na stronie i zachowania użytkowników </p>' +
          '<p class = "heading"> Czas przetwarzania </p>' +
          '<p> Podczas odwiedzania witryny </p>'
        ],
        ro: [
          '<p class = "heading"> Procesoare și destinatari </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Scop </p>' +
          '<p> Analiza traficului site-ului și comportamentul utilizatorului </p>' +
          '<p class = "heading"> Timp de procesare </p>' +
          '<p> În timp ce vizitați site-ul web </p>'
        ],
        hu: [
          '<p class = "heading"> Processzorok és címzettek </p>' +
          "<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>",
          '<p class = "heading"> Cél </p>' +
          '<p> Weboldal forgalmának elemzése és felhasználói viselkedés </p>' +
          '<p class = "heading"> Feldolgozási idő </p>' +
          "<p> A webhely felkeresése közben </p>"
        ],
        sk: [
          '<p class="heading">Spracovatelia a príjemcovia</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Krenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Analýza návštevnosti webu a správania používateľov</p>' +
          '<p class="heading">Doba spracovania</p>' +
          '<p> Počas návštevy webových stránok </p>'
        ],
      }
    },
    marketing: {
      option: {
        cz: 'Marketingové',
        en: 'Marketing',
        de: 'Marketing',
        pl: 'Marketing',
        ro: 'Marketing',
        hu: 'Marketing',
        sk: 'Marketingové'
      },
      info: {
        cz: 'Cílem těchto cookies je propojit náš web se sociálními a reklamními sítěmi 3. stran jako je např. Facebook nebo Google Ads. Díky tomuto propojení vám můžeme zobrazovat relevantní reklamu i mimo náš web dle vašich preferencí, abychom vás neobtěžovali něčím, co vás nezajímá.',
        en: 'The purpose of these cookies is to connect our website with social and advertising networks of third parties, such as Facebook or Google Ads. This link allows us to show you relevant ads outside outside of our site according to your preferences, so that we don\'t bother you with something that doesn\'t interest you.',
        de: 'Der Zweck dieser Cookies besteht darin, unsere Website mit sozialen und Werbenetzwerken von Drittanbietern wie Facebook oder Google Ads zu verbinden. Dieser Link ermöglicht es uns, Ihnen relevante Anzeigen außerhalb unserer Website gemäß Ihren Präferenzen anzuzeigen, damit wir Sie nicht mit etwas belästigen, das Sie nicht interessiert.',
        pl: 'Celem tych plików cookie jest połączenie naszej strony internetowej z sieciami społecznościowymi i reklamowymi podmiotów trzecich, takimi jak Facebook czy Google Ads. Ten link pozwala nam wyświetlać odpowiednie reklamy poza poza naszą witryną zgodnie z Twoimi preferencjami, abyśmy nie zawracali Ci głowy czymś, co Cię nie interesuje.',
        ro: 'Scopul acestor cookie-uri este de a conecta site-ul nostru cu rețelele sociale și de publicitate ale terților, cum ar fi Facebook sau Google Ads. Acest link ne permite să vă arătăm reclame relevante în afara în afara site-ului nostru în funcție de preferințele dumneavoastră, astfel încât să nu vă deranjam cu ceva care nu vă interesează.',
        hu: 'Ezen sütik célja, hogy összekapcsolják weboldalunkat harmadik felek közösségi és hirdetési hálózataival, mint például a Facebook vagy a Google Ads. Ez a hivatkozás lehetővé teszi számunkra, hogy az Ön preferenciái szerint releváns hirdetéseket jelenítsünk meg webhelyünkön kívül, hogy ne zavarjuk Önt olyasmivel, ami nem érdekli.',
        sk: 'Cieľom týchto cookies je prepojiť náš web so sociálnymi a reklamnými sieťami 3. strán ako je napr. Facebook alebo Google Ads. Vďaka tomuto prepojeniu vám môžeme zobrazovať relevantnú reklamu i mimo náš web podľa vašich preferencií, aby sme vás neobťažovali niečím, čo vás nezaujíma.'
      },
      content: {
        cz: [
          '<p class="heading">Zpracovatelé a příjemci</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Křenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Marketingové aktivity</p>' +
          '<p class="heading">Doba zpracování</p>' +
          '<p> Po dobu návštěvy webových stránek </p>'
        ],
        en: [
          '<p class = "heading"> Processors and recipients </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Purpose </p>' +
          '<p> Marketing activities</p>' +
          '<p class = "heading"> Processing time </p>' +
          '<p> While visiting the website </p>'
        ],
        de: [
          '<p class = "heading"> Prozessoren und Empfänger </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Zweck </p>' +
          '<p> Marketing Aktivitäten</p>' +
          '<p class = "heading"> Bearbeitungszeit </p>' +
          '<p> Beim Besuch der Website </p>'
        ],
        pl: [
          '<p class = "heading"> Procesory i odbiorcy </p>' +
          '<p> <strong>SOVA NET, s.r.o. </strong>, IČO: 26281813 <br />Křenová 409/52, Trnitá, Brno </p>' +
          '<p class = "heading"> Cel </p>' +
          '<p> Działania marketingowe</p>' +
          '<p class = "heading"> Czas przetwarzania </p>' +
          '<p> Podczas odwiedzania witryny </p>'
        ],
        ro: [
          '<p class = "heading"> Procesoare și destinatari </p>' +
          '<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>',
          '<p class = "heading"> Scop </p>' +
          '<p> Activitati de marketing</p>' +
          '<p class = "heading"> Timp de procesare </p>' +
          '<p> În timp ce vizitați site-ul web </p>'
        ],
        hu: [
          '<p class = "heading"> Processzorok és címzettek </p>' +
          "<p> <strong> SOVA NET, s.r.o. </strong>, IČO: 26281813 <br /> Křenová 409/52, Trnitá, Brno </p>",
          '<p class = "heading"> Cél </p>' +
          '<p> Marketing tevékenységek</p>' +
          '<p class = "heading"> Feldolgozási idő </p>' +
          "<p> A webhely felkeresése közben </p>"
        ],
        sk: [
          '<p class="heading">Spracovatelia a príjemcovia</p>' +
          '<p><strong>SOVA NET, s.r.o.</strong>, IČO: 26281813<br />Krenová 409/52, Trnitá, Brno</p>',
          '<p class="heading">Účel</p>' +
          '<p> Marketingové aktivity</p>' +
          '<p class="heading">Doba spracovania</p>' +
          '<p> Počas návštevy webových stránok </p>'
        ],
      }
    }
  },
  openCloseButton: {
    text: {
      cz: 'Cookies',
      en: 'Cookies',
      de: 'Cookies',
      pl: 'Cookies',
      ro: 'Cookies',
      hu: 'Cookies',
      sk: 'Cookies'
    }
  },
  // purpose: {
  // cz: 'Účel',
  // en: 'Purpose',
  // de: 'Zweck',
  // pl: 'Zamiar',
  // ro: 'Scop',
  // hu: 'Célja',
  // sk: 'Účel'
  // },

  template: {
    cz: '',
    en: '',
    de: '',
    pl: '',
    ro: '',
    hu: '',
    sk: ''
  }
}

export default Dictionary
