import {useState} from "react";
import HeaderText from './Component/HeaderText'
import ControlPanel from './Component/ControlPanel'
import CookieOptions from './Component/CookieOptions'
import CookiesInfo from './Component/CookiesInfo'
import CookieContext, {defaultCookiesOptions} from "./Context/CookieContext";
import Cookies from 'js-cookie';
import CookieManager from './CookieManager'
import CookiesOpenCloseButton from './Component/CookiesOpenCloseButton'
function App() {
  const [cookiesOptions, _setCookiesOptions] = useState(Object.assign({}, defaultCookiesOptions))
  const [showCookieManager, setShowCookieManager] = useState(process.env.NODE_ENV !== 'production' ? true : !Cookies.get('cm'))
  const [showInfo, setShowInfo] = useState(false)
  const [_refreshCount, _refresh] = useState(0);

  window.cookieManager = window.cookieManager || CookieManager
  const isMinimal = CookieManager.settings.minimal
  const showInfoButton = CookieManager.settings.showInfoButton
  window.cookieManager.open = () => setShowCookieManager(true)
  window.cookieManager.close = () => setShowCookieManager(false)
  window.cookieManager.toggle = () => setShowCookieManager(!showCookieManager)
  window.cookieManager._onRefresh = () => _refresh(_refreshCount+1)

  const setCookiesOption = (tid, selected) => {
    _setCookiesOptions(Object.assign({}, cookiesOptions, {[tid]: selected}))
  }

  const setCookiesOptions = (options, showCookieManager = false) => {
    _setCookiesOptions(Object.assign({}, cookiesOptions, options))
    setShowCookieManager(showCookieManager)
    Cookies.set('cm', true, { expires: 365 })
    setShowInfo(false)
  }

  const cookieContext = {
    cookies: cookiesOptions,
    setOption: setCookiesOption,
    setOptions: setCookiesOptions,
    cookieManager: window.cookieManager
  }

  return showCookieManager ? (
    <div className="cookies-bar cookie-manager-background-color-095 cookies-bar--opened">
      <div className="c-1100 cookies-bar__inner">
        <HeaderText />
        <CookieContext.Provider value={cookieContext}>
          <ControlPanel minimal={isMinimal} onMoreOptions={() => CookieManager.setMinimal(!isMinimal)} />
          {isMinimal || <CookieOptions onInfoOpenChange={(opened) => setShowInfo(opened)} showInfoButton={showInfoButton} />}
          {showInfo && <CookiesInfo />}
        </CookieContext.Provider>
      </div>
    </div>
  ) : <CookiesOpenCloseButton />
}

export default App
