import React from 'react'
import ReactDOM from 'react-dom'
import './Styles/CookieBar.scss'
import App from './App'
import CookieManager from "./CookieManager"
import GTM from './GTM'
// import reportWebVitals from './reportWebVitals';

window.cookieManager = window.cookieManager || CookieManager

const cookieManagerId = 'embedded-cookies-2022'

// Auto init root element
let cookieManagerJS = document.createElement('div')
cookieManagerJS.id = cookieManagerId
document.body.appendChild(cookieManagerJS)

// init GTM Cookies Consent is set
// if (CookieManager.isUsingGTM()) {
//   try {
//     GTM.initDefaultCookiesOptions()
//   } catch (e) {}
// }

// window.cookieManager.addOpenCloseButton('#menu', '<li>cookies</li>')


const urlParams = new URLSearchParams(window.location.search);

// Auto include CSS
if (process.env.NODE_ENV === 'production' && !urlParams.has('no-css')) {
  let cookieManagerCSS = document.createElement('link');
  cookieManagerCSS.rel = 'stylesheet'
  cookieManagerCSS.href = 'https://cookies.sovanet.cz/static/css/main.css'
  document.body.appendChild(cookieManagerCSS);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(cookieManagerId)
);

// window.cookieManager.setMinimal()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
