const CookiesInfoItem = ({title, info, children, className = ''}) => {
  console.log('children', children)
  return (
    <div className={"cookies-settings__item cookies-manager-background-color-mine-shaft " + className}>
      <header className="cookies-settings__header">
        <h3 className="cookies-settings__title cookies-manager-color-white ">{title}</h3>
      </header>
      <main className={"cookies-manager-color-white "}>
        <div className="formatted-text">
          <div dangerouslySetInnerHTML={{__html: info}} />
        </div>
        {children && Array.isArray(children) && children.length > 0 && <div className="cookies-settings__info">
          {children.map((child, i) => (
            <div key={i} className="cookies-settings__info-item cookies-manager-background-color-gray-dark formatted-text" dangerouslySetInnerHTML={{__html: child}} />
          ))}
        </div>}
        {children && !Array.isArray(children) && children.length > 0 && <div className="cookies-settings__info" dangerouslySetInnerHTML={{__html: children}} />}
      </main>
    </div>
  )
}

export default CookiesInfoItem
