import CookiesInfoItem from './CookiesInfoItem'
import {_} from '../Translations/Translations'

const CookiesInfo = () => {
  return (
    <div className="cookies-bar__settings-container" id="cookies-bar-settings">
      <CookiesInfoItem
        title={_('cookie.technical.option') + " cookies"}
        info={_('cookie.technical.info')}
        className="cookies-settings__item--disabled"
      >{_('cookie.technical.content')||null}</CookiesInfoItem>
      <CookiesInfoItem
        title={_('cookie.preferential.option') + " cookies"}
        info={_('cookie.preferential.info')}
      >{_('cookie.preferential.content')||null}</CookiesInfoItem>
      <CookiesInfoItem
        title={_('cookie.analytical.option') + " cookies"}
        info={_('cookie.analytical.info')}
      >{_('cookie.analytical.content')||null}</CookiesInfoItem>
      <CookiesInfoItem
        title={_('cookie.marketing.option') + " cookies"}
        info={_('cookie.marketing.info')}
      >{_('cookie.marketing.content')||null}</CookiesInfoItem>
    </div>
  )
}

export default CookiesInfo
