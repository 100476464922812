import {useContext} from "react";
import ControlButton from "./ControlButton";
import CookieContext, {defaultCookiesOptions} from "../Context/CookieContext";
import Cookies from "js-cookie";
import {_} from '../Translations/Translations'

// Array of callbacks to be executed when consent changes
export const cmCookiesConsentListeners = [];

/**
 *   Called from GTM template to set callback to be executed when user consent is provided.
 *   @param {function} callback to execute on user consent
 */
window.addConsentCMCookieListener = (callback) => {
  console.log('REGISTERED callback', callback)
  cmCookiesConsentListeners.push(callback);
};

dispatchEvent(new CustomEvent('cookieMangerLoaded'))

const ControlPanel = ({minimal, onMoreOptions}) => {
  const cookieContext = useContext(CookieContext)
  const setCookies = (options) => {
    Object.keys(options).forEach(cookie => {
      if (options[cookie]) {
        Cookies.set('cm-' + cookie, options[cookie], { expires: 365 })
      } else {
        Cookies.remove('cm-' + cookie)
      }
    })

    console.log('cmCookiesConsentListeners', cmCookiesConsentListeners)

    cmCookiesConsentListeners.forEach((callback) => {
      callback({
        cookies: options
      });
    });

    cookieContext.setOptions(options);
    cookieContext.cookieManager.onCookiesChange(options);
  }
  const onPredefinedOptionSelect = (key) => {
    const options = {}
    switch(key) {
      case 'all':
        Object.keys(defaultCookiesOptions).forEach(cookie => options[cookie] = true)
        break;
      case 'selected':
        Object.keys(defaultCookiesOptions).forEach(cookie => options[cookie] = false)
        Object.keys(defaultCookiesOptions).forEach(cookie => options[cookie] = cookieContext?.cookies[cookie]||false)
        break;
      case 'necessary':
        Object.keys(defaultCookiesOptions).forEach(cookie => options[cookie] = false)
        break;
      default:
    }
    options['technical'] = true
    setCookies(options)
  }

  return <div className={"cookies-bar__btn-container " + (minimal ? "minimal" : "nominimal")}>
    {(minimal &&
      <ControlButton text={_('button.moreOptions')} onOptionSelect={() => onMoreOptions()}
                     className={"cookies-manager-btn-color cookies-manager-background-color-alto"} />) || <>
      <ControlButton text={_('button.confirmNecessary')} tid="necessary" onOptionSelect={onPredefinedOptionSelect}
                     className={"cookies-manager-btn-color cookies-manager-background-color-alto"} />
      <ControlButton text={_('button.confirmSelected')}  tid="selected" onOptionSelect={onPredefinedOptionSelect}
                     className={"cookies-manager-btn-color cookies-manager-background-color-alto"} />
    </>}
    <ControlButton text={_('button.confirmAll')} tid="all"
                   onOptionSelect={onPredefinedOptionSelect}
                   className="cookies-bar__btn--full cookies-manager-btn-main-color cookies-manager-background-color-monza" />
  </div>
}

export default ControlPanel
