import CookieOption from './CookieOption'
import {useState} from "react";
import {_} from '../Translations/Translations'

const CookieOptions = ({onInfoOpenChange, showInfoButton = true}) => {
  const [opened, toggle] = useState(false)
  const handleShow = (e) => {
    e.preventDefault()
    toggle(!opened)
    onInfoOpenChange && onInfoOpenChange(!opened)
  }

  return (
    <div className="cookies-bar__switch-container">
      <div className="switches">
        <CookieOption tid="technical" text={_('cookie.technical.option')} defaultChecked disabled/>
        <CookieOption tid="preferential" text={_('cookie.preferential.option')} />
        <CookieOption tid="analytical" text={_('cookie.analytical.option')} />
        <CookieOption tid="marketing" text={_('cookie.marketing.option')} />
      </div>
      {showInfoButton && <button className="btn btn--small cookies-bar__btn cookies-manager-background-color-alto cookies-manager-btn-color with-active cookies-bar__btn_info cookies-manager-color-white " onClick={(e) => handleShow(e)}>
        {opened ? _('button.infoHide') : _('button.infoShow')}
      </button>}
    </div>
  )
}

export default CookieOptions
