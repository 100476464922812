
export default new function() {
  let dataLayerName = 'dataLayer'
  let defaultConsentOptions = ['technical']
  let mapConsentFromCookiesOptions = {
    technical: ['functionality_storage', 'security_storage'],
    analytical: ['analytics_storage'],
    preferential: ['personalization_storage'],
    marketing: ['ad_storage', 'ad_user_data', 'ad_personalization', 'ad_user_data', 'ad_personalization']
  }

  const setDataLayerName = (_dataLayerName) => {
    if (_dataLayerName) {
      dataLayerName = _dataLayerName
    }
  }

  const setMapConsentFromCookiesOptions = (_mapConsentFromCookiesOptions) => {
    mapConsentFromCookiesOptions = _mapConsentFromCookiesOptions
  }

  const _mapConsentFromCookiesOptions = (options) => {
    let map = {}
    Object.keys(options).forEach(optionIndex => {
      mapConsentFromCookiesOptions[optionIndex].forEach(name => {
        map[name] = options[optionIndex] ? 'granted' : 'denied'
      })
    });
    return map
  }

  const initDefaultCookiesOptions = () => {
    console.log('INIT COOKIES CONSENT', {..._mapConsentFromCookiesOptions(defaultConsentOptions), ...{'wait_for_update': 2000}})

    const dataLayerName = dataLayerName || 'dataLayer';
    window[dataLayerName] = window[dataLayerName] || [];
    (function(){
      window[dataLayerName].push(arguments);
    })('consent', 'default',  {..._mapConsentFromCookiesOptions(defaultConsentOptions), ...{'wait_for_update': 2000}});
  }

  const pushCookiesConsentChangeToDataLayer = (options) => {

    console.log('_mapConsentFromCookiesOptions(options)', _mapConsentFromCookiesOptions(options))

    const dataLayerName = dataLayerName || 'dataLayer';
    window[dataLayerName] = window[dataLayerName] || [];
    (function(){
      window[dataLayerName].push(arguments);
    })('consent', 'update',  _mapConsentFromCookiesOptions(options));
  }

  return {
    setDataLayerName,
    setMapConsentFromCookiesOptions,
    pushCookiesConsentChangeToDataLayer,
    initDefaultCookiesOptions
  }
}
