import Dictionary from "./Translations/Dictionary"
import GTM from './GTM'

class CookieManager {
  settings = {
    lang: 'cz', // default language
    hideOpenCloseButton: false,
    openCloseButtonVisibilityOffset: 150,
    minimal: false,
    showInfoButton: true,
    useGTM: false
  }

  applySettings(settings) {
    this.settings = Object.assign(this.settings, settings)
    this.refresh()
  }

  // Will be overwritten
  toggle () {}

  // Will be overwritten
  _onRefresh() {}

  refresh() {
    this._onRefresh()
  }

  useGTM() {
    this.settings.useGTM = true
    // GTM.initDefaultCookiesOptions()
  }

  isUsingGTM() {
    return this.settings.useGTM === true
  }

  onCookiesChange(options) {
    this.settings.useGTM
      && GTM.pushCookiesConsentChangeToDataLayer(options)
  }

  setMapConsentFromCookiesOptions(map) {
    GTM.setMapConsentFromCookiesOptions(map)
  }

  setMinimal(minimal = true) {
    this.settings.minimal = minimal
    this._onRefresh()
  }

  setShowInfoButton(showInfoButton = true) {
    this.settings.showInfoButton = showInfoButton
    this._onRefresh()
  }

  /**
   * Set cookies manager, bar language
   * @param lang
   */
  setLang(lang) {
    this.settings.lang = lang
    this._onRefresh()
  }

  /**
   * For adding translations dynamically (e.g. TAG)
   *
   * @param key string (dot notation supported)
   * @param translation string
   * @param lang string
   */
  addTranslation(key, translation, lang) {
    this._setTranslation(key, translation, lang, true)
  }

  setTranslation(key, translation, lang) {
    this._setTranslation(key, translation, lang)
  }

  /**
   * For adding translations dynamically (e.g. TAG)
   * Parameter "translations" is object where keys are languages codes
   *
   * @param key string (dot notation supported)
   * @param translations string[]
   */
  setTranslations(key, translations) {
    this._setTranslation(key, translations)
  }

  addTranslations(key, translations) {
    this._setTranslation(key, translations, null, true)
  }

  /**
   * @param key string
   * @param translation string
   * @param lang string|null
   * @param add boolean
   * @private
   */
  _setTranslation(key, translation, lang = null, add = false) {
    // console.log('set translation: ', translation)
    let translationInDictionary = {}
    let arrayPart = null

    key.split('.').forEach((part, i) => {
      if (i === 0) {
        translationInDictionary[part] = Dictionary[part] !== undefined ? Dictionary[part] : {}
        translationInDictionary = translationInDictionary[part]
      } else {
        if (/^\d+$/.test(part)) {
          arrayPart = parseInt(part)
        } else {
          translationInDictionary[part] = translationInDictionary[part] !== undefined ? translationInDictionary[part] : {}
          translationInDictionary = translationInDictionary[part]
        }
      }
    })
    if (lang) {
      const addItem = add && Array.isArray(translation) ? [...translationInDictionary[lang], ...translation] : [...translation];
      if (arrayPart !== null && arrayPart >= 0) {
        translationInDictionary[lang][arrayPart] = Array.isArray(translation) ? [...addItem] : (add ? translationInDictionary[lang] : '') + translation
      } else {
        translationInDictionary[lang] = Array.isArray(translation) ? [...addItem] : (add ? translationInDictionary[lang] : '') + translation
      }
    } else {
      if (add) {
        Object.keys(translation).forEach((key, index) => {
          const isArrayOriginal = Array.isArray(translationInDictionary[key])
          const isArray = Array.isArray(translation[key])
          translation[key] = isArrayOriginal
            ? translationInDictionary[key].map((item, index) => {
              const isArraySubItem = Array.isArray(translation[key][index])
              const val = translation[key][index] !== undefined ? translation[key][index] : ''
              return isArray ? ( isArraySubItem ? [item, translation[key][index]] : item + val) : item + translation[key]
            })
            : translationInDictionary[key] + translation[key]
        })
      }
      if (arrayPart >= 0) {
        Object.keys(translationInDictionary).forEach((item) => {
          translationInDictionary[item][arrayPart] = translation[item]
        })
      } else {
        translationInDictionary = Object.assign(translationInDictionary, translation)
      }
    }
    this._onRefresh()
  }

  addOpenCloseButton(selector, html, position = -1) {
    try {
      const buttonPlacement = document.querySelector(selector);
      const childrenCount = buttonPlacement.children.length
      const placement = position > 0 && position <= childrenCount + 1
        ? position
        : (position < 0 ? childrenCount : (position > childrenCount + 1 ? childrenCount : position))
      const children = [...buttonPlacement.children]
      children.forEach((child, i) => {
        // console.log('i === placement - 2)', i === placement - 1, i, placement - 1)
        if (i === placement - 1) {
          const div = document.createElement('div');
          div.innerHTML = html.trim();
          // Change this to div.childNodes to support multiple top-level nodes
          const el = div.firstChild;
          // child.insertAdjacentHTML('afterend', html)
          if (child.parentNode) {
            el.addEventListener('click', () => this.toggle())
            child.parentNode.insertBefore(el, i === childrenCount -1 ? child.nextSibling : child);
          }
        }
      })

    } catch (e) { console.error('Browser does not support something to addOpenCloseButton() work') }
  }
}

const CookieManagerSingleton = new CookieManager()
export default CookieManagerSingleton
