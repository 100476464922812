import {_} from '../Translations/Translations'
import {useEffect, useState} from "react";

const CookiesOpenCloseButton = () => {
  const visibilityMargin = window.cookieManager.settings.openCloseButtonVisibilityOffset
  const visibilityCond = () => {
    const documentHeight = getDocumentHeight()
    // console.log('cookies: documentHeight', getDocumentHeight())
    // console.log('cookies: window.innerHeight + window.scrollY', window.innerHeight + window.scrollY)
    // console.log('cookies: documentHeight - visibilityMargin', documentHeight - visibilityMargin)
    return (window.innerHeight + window.scrollY) >= (documentHeight - visibilityMargin);
  }
  const visibilityCondValue = visibilityCond()
  const [visible, setVisibility] = useState(visibilityCondValue)
  useEffect(() => {
    const scrollListener = function() {
      // console.log('visibilityCond in listener', visibilityCond(), (window.innerHeight + window.scrollY),  (getDocumentHeight() - visibilityMargin))
      setVisibility(visibilityCond())
    }
    document.addEventListener('scroll', scrollListener)
    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [])


  // console.log('cookies: window.cookieManager.settings.hideOpenCloseButton', window.cookieManager.settings.hideOpenCloseButton)
  // console.log('cookies: visible', visible)

  return window.cookieManager.settings.hideOpenCloseButton ? null : (visible && (
    <div onClick={() => window.cookieManager.open()}
         className={"cookies-manager-open-close__button cookies-manager-oc-button-color cookies-manager-oc-background-color with-active "}>
      {_('openCloseButton.text')}
    </div>
  ))
}

// const getDocumentHeight = () => {
//   const element = document.createElement('div')
//   element.style.visibility = "hidden"
//   document.body.appendChild(element)
//   let height = element.offsetTop
//   document.body.removeChild(element)
//   element.style.visibility = "visible"
//   return height
// }

function getDocumentHeight() {	// $(document).height() value depends on browser
  const D = document;
  return Math.max(
    D.body.scrollHeight, D.documentElement.scrollHeight,
    D.body.offsetHeight, D.documentElement.offsetHeight,
    D.body.clientHeight, D.documentElement.clientHeight
  );
}


export default CookiesOpenCloseButton
