import Dictionary from './Dictionary'

export function _(key) {
  const lang = window.cookieManager.settings.lang
  const keySplit = key.split('.')
  let translation = Dictionary
  keySplit.forEach(part => {
      translation = translation[part] !== undefined ? translation[part] : false
  })
  return translation[lang] === undefined ? Dictionary['missingTranslation'][lang] : translation[lang]
}
