const ControlButton = ({tid, text, onOptionSelect, className = ''}) => {
  const htmlId = `cookies-bar-btn-${tid}`
  const _onClick = (e) => {
    e.preventDefault()
    onOptionSelect && onOptionSelect(tid)
  }
  return (
    <button onClick={_onClick} className={"btn btn--small cookies-bar__btn with-active " + className} id={htmlId}>{text}</button>
  )
}

export default ControlButton
