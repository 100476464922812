import {createContext} from "react";
import Cookies from 'js-cookie';

export const defaultCookiesOptions = {
  technical: true,
  preferential: false,
  analytical: false,
  marketing: false
}

Object.keys(Cookies.get()).forEach((cookie) => {
  let found = [];
  if ((found = cookie.match(/^cm-(.+)/)) && found[1]) {
    defaultCookiesOptions[found[1]] = Cookies.get('cm-' + found[1]) === 'true'
  }
})

const cookieContext = {
  cookies: {},
  set: (key, checked) => {
    cookieContext.cookies[key] = checked
  }
}

export default createContext(cookieContext)
